.root {
  background-image: url(../../../../assets/cta-banner.png);
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: 425px) {
    background-image: url(../../../../assets/ctaMobile.png);
  }
}

.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.ctaContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 48px;
  padding: 13px 184px 100px 184px;

  @media (max-width: 1024px) {
    padding: 40px 24px 84px 24px;
    gap: 24px;
  }

  @media (max-width: 425px) {
    padding: 0;
    gap: 24px;
  }
}

h2.title {
  font-weight: var(--fontWeightBold);
  font-size: var(--fontCTA);
  line-height: 48px;
  text-align: center;
  color: var(--colorWhite);
  
  @media (max-width: 768px) {
    line-height: 28.8px;
    font-size: var(--fontSizeMedium);
  }
}

.ctaButton:hover {
  background: var(--colorPrimary);
}
a.ctaButton:hover {
  color: var(--colorWhite) !important;
}
a.ctaButton {
  padding: 18px 24px 16px;
  background: var(--colorSecondary);
  border-radius: 8px;
  width: 168px;
  text-align: center;
  font-size: var(--fontSizeMedium);
  line-height: 24px;
  color: var(--colorWhite);
  text-decoration: none !important;
  font-weight: var(--fontWeightSemiBold);
  
  @media (max-width: 768px) {
    padding: 14px 24px 14px;
    font-size: var(--fontSizeRegular);
  }

}
